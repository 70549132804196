import Link from "next/link";

export default function Footer1() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-490bc5c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="490bc5c"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ddac542"
          data-id="ddac542"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-740ffbf elementor-invisible elementor-widget elementor-widget-heading"
              data-id="740ffbf"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInUp"}'
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h5 className="elementor-heading-title elementor-size-default">
                  let’s talk
                </h5>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-e02e409 elementor-widget elementor-widget-heading"
              data-id="e02e409"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  Brace Yourself As Your Business is Soon Going to be Upgraded
                </h3>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-cc4ce4b elementor-widget elementor-widget-text-editor"
              data-id="cc4ce4b"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  Just book a call and get access to ISO auditing services.
                </h3>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-7c4548a elementor-align-center elementor-invisible elementor-widget elementor-widget-button"
              data-id="7c4548a"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInDown"}'
              data-widget_type="button.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-button-wrapper">
                  <Link
                    rel="dofollow"
                    href="/contact-us/"
                    title="Contact IS Auditr"
                    className="elementor-button-link elementor-button elementor-size-md"
                  >
                    <span className="elementor-button-content-wrapper">
                      <span className="elementor-button-text">Contact us</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
